import React from "react"
import Img from "gatsby-image"
import font from "../../fonts/fonts.module.scss"
import { useTranslation } from 'gatsby-plugin-react-i18next'

import styled from "styled-components"
import Div100vh from 'react-div-100vh';
import { device } from '../device'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

import filter from "../../images/contactos/filter.svg"
import phone from "../../images/contactos/phone.svg"
import mail from "../../images/contactos/mail.svg"
import location from '../../images/contactos/location.svg'

const HomeScreen = ({ data, sidebar }) => {
  const {t} = useTranslation(['contactos'])
  const breakpoints = useBreakpoint()

  return(
    <StyledHome>
      {sidebar}
      <Img fluid={data.background.childImageSharp.fluid} style={{ height: `100%`, position: "absolute" }} imgStyle={{ objectFit: "cover" }} className="background"/>
      <img src={filter} alt="Filtro de imagem" className="background" />

      <div className="info">
        <div className='left-container'>
          <div className="icons">
            <a href='https://www.google.pt/maps/place/Saven+-+Sociedade+Abastecedora+De+Navios+Aveirense,+S.A./@40.600856,-8.7217015,17z/data=!3m1!4b1!4m5!3m4!1s0xd23963a0f5f3eab:0x9c2f773385967924!8m2!3d40.6008519!4d-8.7195128?hl=pt-PT&authuser=0'><img src={location} alt="Localizacao" className="icon" /></a>
          </div>
          {t('morada', {returnObjects: true}).map((data, i) =>(
            <h3 className={font.nSR} key={i}>{data}</h3>
          ))}
          <a href="https://www.google.pt/maps/place/Saven+-+Sociedade+Abastecedora+De+Navios+Aveirense,+S.A./@40.600856,-8.7217015,17z/data=!3m1!4b1!4m5!3m4!1s0xd23963a0f5f3eab:0x9c2f773385967924!8m2!3d40.6008519!4d-8.7195128?hl=pt-PT&authuser=0" target='_blank' rel="noreferrer"><p className={font.aGGB + " contactLink"}>{t('gMaps')}</p></a>
        </div>

        <div className='right-container'>
          <div className="icons">
            <a href='tel:+351234329530'><img src={phone} alt="Telefone" className="icon" /></a>
            <a href='mailto:saven@saven.pt'><img src={mail} alt="Email" className="icon" /></a>
          </div>
          <a href='tel:+351234329530'><h3 className={font.nSR}>Tel. {t('tel')}</h3></a>
          <h6 className={font.nSR}>{t('Chamada para rede fixa nacional')}</h6>
          <br></br>
          <a href='tel:+351234329531'><h3 className={font.nSR}>Fax. {t('fax')}</h3></a>
          <h6 className={font.nSR}>{t('Chamada para rede fixa nacional')}</h6>
          <a href='mailto:saven@saven.pt'><h3 className={font.nSR}>{t('email')}</h3></a>
        </div>
        {breakpoints.mobile &&
          <div className="infoFtrMobile">
            <h5 className={font.aGGB}>{t('infoFtr')}</h5>
          </div>
        }
      </div>
      {!breakpoints.mobile &&
        <div className="infoFtr">
          <h5 className={font.aGGB}>{t('infoFtr')}</h5>
        </div>
      }
  </StyledHome>
  )
}

export default HomeScreen

const StyledHome = styled(Div100vh)`
  width: 100%;
  position: relative;
  overflow: hidden;
  z-index: 2;
  margin-top:50px;

  .info, .infoFtr{
    h3, h5, p{
      white-space: nowrap;
      font-size: 18px !important;
      @media only screen and (max-width: 500px){
        font-size: 16px !important;space: normal !important;
      }
    }
    @media only screen and (max-width: 500px){
      .infoFtrMobile h5{
        white-space: normal !important;
      }
    }
  }

  h6{
      white-space: nowrap;
      font-size: 11px !important;
      @media only screen and (max-width: 500px){
        font-size: 10px !important;space: normal !important;
      }
    }
    @media only screen and (max-width: 500px){
      .infoFtrMobile h5{
        white-space: normal !important;
      }
    }
  }

  @media ${device.desktop}{
    margin-top:unset;
  }

  .left-container, .right-container{
    padding:0 50px;
  }


  .background{
    z-index:0;
    position:relative !important;

    @media ${device.desktop}{
      position: absolute !important;
      min-height: 100%;
      min-width: 84.35%;
      margin-left: 15.65%;
      z-index: -1;
      top: 0;
    }

    &:after{
      content:'';
      width:100%;
      height:100%;
      position: absolute;
      top:0;
      left:0;
      background:#362921;
      opacity:0.8;

      @media ${device.desktop}{
        display:none;
      }
    }
  }

  .info{
    display: flex;
    justify-content: space-between;
    color: #fff;
    flex-direction:column;
    padding:25px 25px;
    height: calc(100vh - 50px);
    justify-content: space-between;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;

    @media ${device.desktop}{
      width: 60%;
      left: 57.325%;
      top: 50%;
      z-index: unset;
      transform: translate(-50%, -50%);
      flex-direction:unset;
      padding:unset;
      height:unset;
      justify-content:unset;
    }

    .icons{
      display:flex;
      flex-direction:row;
      align-items:center;
      margin-bottom: 20px;

      @media ${device.desktop}{
        margin: 30px 0;
      }

      img{
        margin-right: 40px;
      }
    }

    .contactLink{
      text-decoration: underline;
      margin: 20px 0;
    }
  }

  .infoFtr{
    color: #fff;
    position: absolute;
    left: 32.3%;
    bottom: 10%;
  }

  .infoFtrMobile{
    color: #fff;
  }
`
